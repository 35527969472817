import { CompanyFlags, CompanyIntegrations } from '@global-interfaces/Company';
import { ErpStatus, FlowStatus, ProcoreStatus } from '@global-interfaces/enums';
import Invoice from '@global-interfaces/Invoice';
import { ValueLabelSelector } from '@global-interfaces/ValueLabelSelector';
import {
  STATUS_NEW,
  PROCORE,
  QUICKBOOKS,
  QUICKBOOKS_DESKTOP,
  SAGE,
  STATUS_NONE,
  STATUS_SYNCED,
  STATUS_PROCESSING,
  STATUS_FAILED,
  STATUS_READY,
  QUICKBOOKS_DESKTOP_MAC,
  PROCORE_TIMECARD
} from '@global-utils/defaultValues';
import { addDays, isWeekend } from 'date-fns';
import { isHoliday } from 'date-fns-holiday-us';

export const formatDate = (dateValue, UTC = true, time = false) => {
  if (dateValue === null || dateValue === undefined) {
    return null;
  }

  if ((typeof dateValue === 'string' || dateValue instanceof String) && !dateValue.length) {
    return;
  }

  let date = dateValue;

  if (!(dateValue instanceof Date)) {
    date = new Date(dateValue);
  }

  return new Intl.DateTimeFormat('en-US', {
    ...(UTC ? { timeZone: 'UTC' } : undefined),
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    ...(time ? { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false } : undefined)
  }).format(date);
};

export const formatTimestamp = (dateValue: number, UTC = true, time = false) => {
  if (dateValue === null || dateValue === undefined) {
    return null;
  }

  const date = new Date(dateValue);

  return new Intl.DateTimeFormat('en-US', {
    ...(UTC ? { timeZone: 'UTC' } : undefined),
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    ...(time ? { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false } : undefined)
  }).format(date);
};

export const formatTimestampLong = (timestamp: string) => {
  const date = new Date(parseInt(timestamp));
  const dateString = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(
    date
  );

  const timeString = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format(
    date
  );

  const timezoneString = date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
  return `${dateString} at ${timeString} ${timezoneString}`;
};

export const formatToSend = (dateValue) => {
  if (dateValue instanceof Date) {
    return `${dateValue.getFullYear()}-${dateValue.getMonth() + 1}-${dateValue.getDate()}`;
  }

  return null;
};

export const formatCurrency = (value: number) => {
  const formatter = new Intl.NumberFormat('us', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol'
  });

  const formated = formatter.format(value);

  const negativeNumber = formated.startsWith('-');

  const symbol = negativeNumber ? formated.slice(0, 2) : formated.slice(0, 1);
  const amount = negativeNumber ? formated.slice(2) : formated.slice(1);

  return `${symbol} ${amount}`;
};

// biome-ignore lint/suspicious/noExplicitAny: safe to ignore
export const isNumeric = (value: any) => {
  return !isNaN(parseInt(value));
};

export const unformatDate = (dateString?: string | null) => {
  if (dateString === null || dateString === undefined || !dateString.length) {
    return null;
  }

  //MM/DD/YY to YYYY-MM-DD
  const array = dateString.split('/');
  return `20${array[2]}-${array[0]}-${array[1]}`;
};

export const addBusinessDays = (date: Date, days: number) => {
  let result = new Date(date);
  let count = 0;

  while (count < days) {
    result = addDays(result, 1);

    if (!isWeekend(result) && !isHoliday(result)) {
      count++;
    }
  }
  return result;
};

type ErpCollection =
  | typeof SAGE
  | typeof QUICKBOOKS
  | typeof QUICKBOOKS_DESKTOP
  | typeof PROCORE
  | typeof QUICKBOOKS_DESKTOP_MAC;

interface erpStatusResponse {
  text: null | string;
  className: null | string;
}

interface InvoiceErpStatusCollection {
  erp_status: ErpStatus;
  ei_dynamics_response?: string | null;
  quickbooks_response?: string | null;
  quickbooks_status: ErpStatus;
  quickbooks_desktop_response?: string | null;
  quickbooks_desktop_status: ErpStatus;
  procore_response?: string | null;
  procore_status: ProcoreStatus;
  procore_reference_url?: string;
}

export const erpStatusCollection = (
  invoice: InvoiceErpStatusCollection,
  company: { integrations_available: CompanyIntegrations },
  alignLeft = false
) => {
  const {
    erp_status,
    ei_dynamics_response,
    quickbooks_response,
    quickbooks_status,
    quickbooks_desktop_response,
    quickbooks_desktop_status,
    procore_response,
    procore_status,
    procore_reference_url
  } = invoice;

  const {
    sage_available,
    quickbooks_available,
    quickbooks_desktop_available,
    procore_available,
    quickbooks_desktop_mac_available
  } = company.integrations_available || {};

  // biome-ignore format:  no break line
  const classNameReady = alignLeft ? 'text-capitalize ribbon-two ribbon-two-info' : 'text-capitalize ribbon-three table-td ribbon-three-info';
  // biome-ignore format:  no break line
  const classNameProcessing = alignLeft ? 'text-capitalize ribbon-two ribbon-two-info' : 'text-capitalize ribbon-three table-td ribbon-three-warning';
  // biome-ignore format:  no break line
  const classNameDone = alignLeft ? 'text-capitalize ribbon-two ribbon-two-success' : 'text-capitalize ribbon-three table-td ribbon-three-success';
  // biome-ignore format:  no break line
  const classNameFailed = alignLeft ? 'text-capitalize ribbon-two ribbon-two-danger' : 'text-capitalize ribbon-three table-td ribbon-three-danger';

  const getLink = (text: string, url?: string) => {
    if (!url) {
      return text;
    }
    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${text}</a>`;
  };

  const getErpStatus: {
    (
      erpResponse: string,
      erp: ErpCollection,
      sep: '' | '-'
    ): {
      [STATUS_NONE]: erpStatusResponse;
      [ProcoreStatus.NEW]: erpStatusResponse;
      [ProcoreStatus.READY]: erpStatusResponse;
      [ProcoreStatus.PROCESSING]: erpStatusResponse;
      [ProcoreStatus.DONE]: erpStatusResponse;
      [ProcoreStatus.FAILURE]: erpStatusResponse;
      [ErpStatus.NEED]: erpStatusResponse;
      [ErpStatus.VOIDED]: erpStatusResponse;
    };
  } = (erpResponse, erp, sep) => {
    const processingText = erp === PROCORE ? `${erpResponse} &#x1F7E1;` : `Syncing with ${erp} &#x1F7E1;`;
    return {
      [STATUS_NONE]: { text: null, className: null },
      [ProcoreStatus.NEW]: { text: null, className: null },
      [ErpStatus.NEED]: { text: null, className: null },
      [ErpStatus.VOIDED]: { text: null, className: null },
      [ProcoreStatus.READY]: { text: `Ready to Sync with ${erp} &#x1F7E1;`, className: classNameReady },
      [ProcoreStatus.PROCESSING]: { text: processingText, className: classNameProcessing },
      [ProcoreStatus.DONE]: {
        text: getLink(`Synced with ${erp} &#128994;`, procore_reference_url),
        className: classNameDone
      },
      // biome-ignore format:  no break line
      [ProcoreStatus.FAILURE]: { text: `Failed to Sync with ${erp}: &#128308; </br> ${sep} ${erpResponse}`, className: classNameFailed }
    };
  };

  //QUICKBOOKS_DESKTOP_MAC has the same status as QBDesktop
  const erps = [
    { erp: SAGE, available: sage_available, status: erp_status },
    { erp: QUICKBOOKS, available: quickbooks_available, status: quickbooks_status },
    { erp: QUICKBOOKS_DESKTOP, available: quickbooks_desktop_available, status: quickbooks_desktop_status },
    { erp: QUICKBOOKS_DESKTOP_MAC, available: quickbooks_desktop_mac_available, status: quickbooks_desktop_status },
    { erp: PROCORE, available: procore_available, status: procore_status },
    { erp: PROCORE_TIMECARD, available: procore_available, status: procore_status }
  ];

  const checkRibbonEnabled = () => {
    return erps.some((erp) => erp.available && erp.status !== STATUS_NEW);
  };

  const enabled = checkRibbonEnabled();

  // biome-ignore format:  no break line
  const { text: text_erp, className: className_erp } = getErpStatus(ei_dynamics_response ?? '', SAGE, '')[sage_available ? erp_status : STATUS_NONE];

  // biome-ignore format:  no break line
  const { text: text_qb, className: className_qb } = getErpStatus(quickbooks_response ?? '', QUICKBOOKS, '-')[quickbooks_available ? quickbooks_status : STATUS_NONE];

  // biome-ignore format:  no break line
  const { text: text_qbd, className: className_qbd } = getErpStatus(quickbooks_desktop_response ?? '', QUICKBOOKS_DESKTOP, '-')[quickbooks_desktop_available ? quickbooks_desktop_status : STATUS_NONE];

  // biome-ignore format:  no break line
  const { text: text_procore, className: className_procore } = getErpStatus(procore_response ?? '', PROCORE, '-')[procore_available ? procore_status : STATUS_NONE];

  // biome-ignore format:  no break line
  const { text: text_qb_mac, className: className_qb_mac } = getErpStatus(quickbooks_desktop_response ?? '', QUICKBOOKS_DESKTOP_MAC, '-')[quickbooks_desktop_mac_available ? quickbooks_desktop_status : STATUS_NONE];

  const texts = [text_erp, text_qb, text_qbd, text_qb_mac, text_procore];
  const classNames = [className_erp, className_qb, className_qbd, className_qb_mac, className_procore];

  const buildText = () => {
    const availableTexts = texts.filter((t) => Boolean(t));

    return availableTexts.join('</br></br>');
  };

  //check if order is relevant here
  const className = classNames.find((cn) => cn) || '';

  const availableErps = erps
    .filter((erp) => erp.available)
    .filter((erp) => ![STATUS_NEW, STATUS_NONE].includes(erp.status));
  const availableErp = availableErps?.[0] || STATUS_NONE;

  const _MAP_RIBBON_STATUS = {
    done: STATUS_SYNCED,
    processing: STATUS_PROCESSING,
    failed: STATUS_FAILED,
    ready: STATUS_READY,
    new: '',
    none: ''
  };

  const label = _MAP_RIBBON_STATUS[availableErp.status];

  return {
    enabled,
    ribbon: {
      className,
      label
    },
    toolTip: buildText()
  };
};

export const checkArrayEquality = (a, b) => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((value, index) => value === b[index]);
};

export const browserTls13 = () => {
  if (navigator.userAgent.indexOf('Firefox') != -1) {
    if (parseInt(navigator.userAgent.match(/Firefox\/(.*)/)![1]) >= 63) {
      return true;
    } else {
      return false;
    }
  } else if (navigator.userAgent.indexOf('Edg') != -1) {
    if (parseInt(navigator.userAgent.match(/Edg\/(.*)/)![1]) >= 79) {
      return true;
    } else {
      return false;
    }
  } else if (navigator.userAgent.indexOf('Chrome') != -1) {
    if (parseInt(navigator.userAgent.match(/Chrom(e|ium)\/(.*)/)![2]) >= 69) {
      return true;
    } else {
      return false;
    }
  } else if (navigator.userAgent.indexOf('Edge') != -1) {
    if (parseInt(navigator.userAgent.match(/Edge\/(.*)/)![1]) >= 79) {
      return true;
    } else {
      return false;
    }
  } else if (navigator.userAgent.indexOf('Safari') != -1) {
    return true;
  } else {
    return false;
  }
};

export const getBrowser = () => {
  if (navigator.userAgent.indexOf('Firefox') != -1) {
    return 'Firefox';
  } else if (navigator.userAgent.indexOf('Edg') != -1) {
    return 'Edge';
  } else if (navigator.userAgent.indexOf('Chrome') != -1) {
    return 'Chrome';
  } else if (navigator.userAgent.indexOf('Edge') != -1) {
    return 'Edge';
  } else if (navigator.userAgent.indexOf('Safari') != -1) {
    return 'Safari';
  } else {
    return 'Unknown';
  }
};

export const checkNewMentionsIds = (notes, mentioned) => {
  const newMentions = mentioned.filter((mention) => notes.includes(mention.display));
  return newMentions.map((user) => Number(user.id));
};

export const formatPhone = (phone) => {
  if (!phone) {
    return null;
  }
  return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, phone.length)}`;
};

export const formatEin = (ein) => {
  if (!ein) {
    return null;
  }
  return ein.substring(0, 2).concat('-', ein.substring(2, ein.length));
};

export const returnHasEPayment = (vendor: {
  bank?: {
    account_number?: string | null;
    routing_number?: string | null;
    balance_id?: number | null;
    mercoa_id?: number | null;
  } | null;
}) => {
  return vendor?.bank
    ? Boolean(
        (vendor.bank?.account_number && vendor.bank?.routing_number) ||
          vendor.bank?.balance_id ||
          vendor.bank?.mercoa_id
      )
    : false;
};

export const getAlertMessage = (
  status: 'valid' | 'invalid' | 'expired' | 'error' | 'password-error' | 'password-invalid'
) => {
  switch (status) {
    case 'valid':
      return 'Verification successful. Closing...';
    case 'invalid':
      return 'The code you entered is invalid. Please try again.';
    case 'expired':
      return 'The code you entered has expired. Please try again.';
    case 'password-error':
      return 'Password is required to disable 2-factor authentication.';
    case 'password-invalid':
      return 'The password you entered is incorrect. Please try again.';
    default:
      return 'An error occurred. Please try again or contact support.';
  }
};

export const fileRejectionErrorMessage = (fileRejections, accept) => {
  if (fileRejections.length === 0) {
    return null;
  }

  if (fileRejections.length > 0 && fileRejections[0].errors[0].code === 'file-invalid-type') {
    const normalizedAccept = Object.keys(accept).map((key) => {
      const fileTypes = (accept as { [key: string]: string[] })[key];

      if (fileTypes?.length > 0) {
        return fileTypes.map((item) => `*${item}`).join(', ');
      }

      if (key !== 'image/*' && key.includes('image/')) {
        return `*.${key.replace('image/', '')}`;
      }

      switch (key) {
        case 'image/*':
          return 'image';

        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return '*.xlsx';

        case 'application/vnd.ms-excel':
          return '*.xls';

        case 'application/msword':
          return '*.doc';

        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return '*.docx';

        case 'application/vnd.ms-powerpoint':
          return '*.ppt';

        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          return '*.pptx';

        case 'application/pdf':
          return '*.pdf';

        case 'text/plain':
          return '*.txt';

        default:
          return '';
      }
    });

    return `Only ${normalizedAccept.join(', ')} files are accepted`;
  }

  if (fileRejections.length > 0 && fileRejections[0].errors[0].code === 'file-too-large') {
    return 'File size is too big';
  }

  if (fileRejections.length > 0 && fileRejections[0].errors[0].code === 'too-many-files') {
    return 'Only one file is accepted';
  }

  return null;
};

export const round = (value: number) => {
  return Number((Math.round(value * 1000) / 1000).toFixed(2));
};

export const getOptionLabel = (option: string | ValueLabelSelector, options: ValueLabelSelector[]) => {
  if (typeof option === 'string') {
    return option;
  } else {
    const item = options.find((o) => o.label === option.label);

    if (item) {
      return item.label;
    } else {
      return '';
    }
  }
};

export const getSyncedErps = (invoice: Invoice, companyFlags: CompanyFlags) => {
  const erps = [
    {
      erp: 'Procore',
      isSynced: invoice?.procore_status === ProcoreStatus.DONE,
      flag: companyFlags?.lock_invoice_after_procore_sync
    },
    {
      erp: 'Quickbooks Desktop',
      isSynced: invoice?.quickbooks_desktop_status === ErpStatus.DONE,
      flag: companyFlags?.lock_invoice_after_QBD_sync
    },
    {
      erp: 'Quickbooks Online',
      isSynced: invoice?.quickbooks_status === ErpStatus.DONE,
      flag: companyFlags?.lock_invoice_after_QBO_sync
    }
  ];

  return erps.filter((erp) => erp.isSynced && erp.flag);
};

export const getInvoiceLockedMessage = (invoice: Invoice, companyFlags: CompanyFlags) => {
  if (
    !companyFlags?.lock_invoice_after_procore_sync &&
    !companyFlags?.lock_invoice_after_QBD_sync &&
    !companyFlags?.lock_invoice_after_QBO_sync
  ) {
    return false;
  }

  if (![FlowStatus.APPROVED, FlowStatus.PAID].includes(invoice.flow_status)) {
    return false;
  }

  const syncedErps = getSyncedErps(invoice, companyFlags);

  if (!syncedErps.length) {
    return false;
  }
  return `This invoice is synced with ${syncedErps.map((erp) => erp.erp).join(', ')}`;
};
