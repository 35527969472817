import qs from 'qs';
import api, { PaginatedResponse } from './config';
import { PaymentMethodCard, PaymentMethodBank, PlaidItem } from '@global-interfaces/PaymentMethod';
import { PlaidTransaction } from '@global-interfaces/PlaidConnection';
import LineItem, { ColumnDataTypes } from '@global-interfaces/LineItem';
import BillingPeriod from '@global-interfaces/BillingPeriod';
import { ChangeOrder, ChangeOrderItem, ChangeOrderModal, ChangeOrderStatus } from '@global-interfaces/ChangeOrder';
import PurchaseOrder from '@global-interfaces/PurchaseOrder';
import ScheduledValue from '@global-interfaces/ScheduledValue';
import CostCode, { CostCodeBalance } from '@global-interfaces/CostCode';
import { DocusignRecipient, DocusignSignature, DocusignTemplate } from '@global-interfaces/Docusign';
import { OwnerOrder } from '@global-interfaces/OwnerOrder';
import Invoice from '@global-interfaces/Invoice';
import { PlaidAccount, PlaidInstitution } from 'react-plaid-link';
import { PlaidConnection } from '@global-interfaces/PlaidConnection';
import Payment from '@global-interfaces/Payment';
import { AdjustmentStatus, BudgetAdjustment, BudgetAdjustmentItem } from '@global-interfaces/BudgetAdjustment';
import { PaymentTransaction } from '@global-interfaces/Transaction';

const accountingAPI = {
  purchaseOrder: {
    search: async (page: number, filters: { q: string; page_size: number | undefined }) => {
      return api.get('search/purchase-orders/', {
        params: {
          ...filters,
          page: page ? page : 1
        },
        paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
      });
    },
    fetch: async (id: number) => api.get<PurchaseOrder>(`purchase-orders/${id}/`),
    create: async (data: Partial<PurchaseOrder>) => api.post(`purchase-orders/`, data),
    update: async (id: number, data: Partial<PurchaseOrder>) => api.patch(`purchase-orders/${id}/`, data),
    refreshFile: async (id: number) => api.get(`purchase-orders/${id}/create-or-update-file/`),
    sentViaDocusign: async (filesIds: number[] = [], purchaseOrderId: number) =>
      api.post('docusign/documents-signature/', {
        files_ids: filesIds,
        instance_id: purchaseOrderId,
        type: 'PurchaseOrder'
      }),
    refreshFromProcore: async (commitmentIdList: number[], projectIdList: number[]) =>
      api.post<{ Message: string; success: boolean }>('procore/import-commitments/', {
        commitment_id_list: commitmentIdList,
        project_id_list: projectIdList
      }),
    list: async (
      page: number,
      filters: {
        q?: string;
        page?: number;
        page_size?: number;
        vendor_id?: number | number[];
        project_id?: number | number[];
        project?: number | undefined;
        vendor?: number | undefined;
        billing_period_id?: number | number[];
        payment_method_id?: number | number;
        docusign_status?: string | string[];
        erp_status?: string | string[];
        status?: string | string[];
        procore_status?: string | string[];
        quickbooks_desktop_status?: string | string[];
        quickbooks_status?: string | string[];
      } = {}
    ) => {
      return api.get<PaginatedResponse<PurchaseOrder> | PurchaseOrder[]>('purchase-orders/', {
        params: {
          ...filters,
          page: page ? page : 1
        },
        paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
      });
    },
    listModal: async (
      filters: {
        page_size?: number;
        page?: number;
        cost_code_id?: number[];
        billing_period_id?: number;
        project_id?: number;
        closed_period?: boolean;
      } = {}
    ) => {
      return api.get('purchase-orders/modal/', {
        params: {
          ...filters,
          page_size: filters.page_size ?? 10,
          page: filters.page ?? 1
        },
        paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
      });
    },
    listExport: async (project_id: number) => {
      return api.get<{ task_id: string; message: string }>(
        `accounting/export-xls-contract-report/?project_id=${project_id}`
      );
    }
  },
  changeOrder: {
    fetch: async (id: number) => api.get<ChangeOrder>(`change-orders/${id}/`),
    create: async (data: Partial<ChangeOrder>) => api.post('change-orders/', data),
    update: async (id: number, data: Partial<ChangeOrder>) => api.patch<ChangeOrder>(`change-orders/${id}/`, data),
    refreshFile: async (id: number) => api.get(`change-orders/${id}/create-or-update-file/`),
    sentViaDocusign: async (filesIds: number[] = [], changeOrderId: number) =>
      api.post('docusign/documents-signature/', {
        files_ids: filesIds,
        instance_id: changeOrderId,
        type: 'ChangeOrder'
      }),
    list: async (
      filters: { page_size?: number; page?: number; invoice_id?: number; purchase_order_id?: number } = {}
    ) => {
      return api.get<PaginatedResponse<ChangeOrder>>('v2/change-orders/', {
        params: {
          ...filters,
          page_size: filters.page_size ?? 10,
          page: filters.page ?? 1
        },
        paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
      });
    },
    listModal: async (
      filters: {
        page_size?: number;
        page?: number;
        cost_code_id?: number[];
        billing_period_id?: number;
        project_id?: number;
        status?: ChangeOrderStatus;
        in_scope?: string | boolean;
        has_purchase_order?: boolean;
      } = {}
    ) => {
      return api.get<PaginatedResponse<ChangeOrderModal> | ChangeOrderModal[]>('change-orders/modal/', {
        params: {
          ...filters,
          page_size: filters.page_size ?? 10,
          page: filters.page ?? 1
        },
        paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
      });
    },
    listExport: async (project_id: number) => {
      return api.get<{ task_id: string; message: string }>(
        `accounting/export-xls-change-order-report/?project_id=${project_id}`
      );
    },
    v4: {
      list: async (
        filters: {
          q?: string;
          page?: number;
          page_size?: number;
          vendor_id?: number | number[];
          project_id?: number | number[];
          billing_period_id?: number | number[];
          payment_method_id?: number | number;
          docusign_status?: string | string[];
          erp_status?: string | string[];
          status?: string | string[];
          procore_status?: string | string[];
          quickbooks_desktop_status?: string | string[];
          quickbooks_status?: string | string[];
        } = {}
      ) => {
        return api.get<PaginatedResponse<ChangeOrder> | ChangeOrder[]>('v4/change-orders/', {
          params: {
            ...filters,
            page_size: filters.page_size ?? 10,
            page: filters.page ?? 1
          },
          paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
        });
      }
    }
  },
  ownerOrder: {
    fetch: async (id: number) => api.get(`owner/change-orders/${id}/`),
    create: async (data: Partial<OwnerOrder>) => api.post('owner/change-orders/', data),
    update: async (id: number, data: Partial<OwnerOrder>) => api.patch(`owner/change-orders/${id}/`, data),
    refreshFile: async (id: number) => api.get(`owner/change-orders/${id}/create-or-update-file/`),
    list: async (filters: { page_size?: number; page?: number; project_id?: number | null } = {}) => {
      return api.get('owner/change-orders/', {
        params: {
          ...filters,
          page_size: filters.page_size ?? 10,
          page: filters.page ?? 1
        },
        paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
      });
    },
    sentViaDocusign: async (filesIds: number[] = [], ownerOrderId: number) =>
      api.post('docusign/documents-signature/', {
        files_ids: filesIds,
        instance_id: ownerOrderId,
        type: 'OwnerChangeOrder'
      })
  },
  changeOrderItems: {
    fetch: async (id: number) => api.get(`change-orders/items/${id}/`),
    create: async (data: Partial<ChangeOrderItem> & { invoice_id?: number }) => api.post('change-orders/items/', data),
    update: async (id: number, data: Partial<ChangeOrderItem>) => api.patch(`change-orders/items/${id}/`, data),
    bulkUpdate: async (data: {
      ids: number[];
      data: Partial<ChangeOrderItem>;
    }) => api.patch('change-orders/items/bulk-update/', data),
    list: async (filters: { page?: number; page_size?: number; change_order_id?: number } = {}) => {
      return api.get<PaginatedResponse<ChangeOrderItem> | ChangeOrderItem[]>('change-orders/items/', {
        params: {
          ...filters,
          page_size: filters.page_size ?? 10,
          page: filters.page ?? 1
        }
      });
    },
    delete: async (id: number) => api.delete(`change-orders/items/${id}/`)
  },
  billingPeriod: {
    fetch: async (id: number) => api.get(`billing-periods/${id}/`),
    create: async (data: { start_date: string; end_date: string; project: number }) =>
      api.post<BillingPeriod>('billing-periods/', data),
    update: async (id: number, data: Partial<BillingPeriod>) => api.patch(`billing-periods/${id}/`, data),
    list: async (page: number, filters: { page_size?: number; unassigned?: boolean; project?: number | null }) => {
      return api.get<PaginatedResponse<BillingPeriod> | BillingPeriod[]>('billing-periods/', {
        params: {
          ...filters,
          page: page ? page : 1
        },
        paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
      });
    },
    delete: async (id: number) => api.delete(`billing-periods/${id}/`)
  },
  costCode: {
    create: async (data: Partial<CostCode>) => api.post<CostCode>('cost-codes/', data),
    //?all=true should be removed once the problem is solved in the backend
    update: async (id: number, data: Partial<CostCode>) => api.patch<CostCode>(`cost-codes/${id}/?all=true`, data)
  },
  costCodeBalance: {
    getByProject: async (project_id: number) =>
      api.get<CostCodeBalance[]>(`budgets/cost-code-balance/?project_id=${project_id}`)
  },
  scheduledValue: {
    create: async (data: Partial<ScheduledValue>) => api.post<ScheduledValue>(`scheduled-values/`, data),
    update: async (id: number, data: Partial<ScheduledValue>) =>
      api.patch<ScheduledValue>(`scheduled-values/${id}/`, data),
    delete: async (id: number) => api.delete(`scheduled-values/${id}/`)
  },
  lineItem: {
    // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
    create: async (data: any) => api.post<LineItem>('line-items/', data),
    update: async (id: number, data: LineItem) => api.patch<LineItem>(`line-items/${id}/`, data),
    // it will return the line item with the updated object fragment. Ex: if you update the amount, instead of return the whole line item, it will return only the amount.
    // the reason to do that is to manage the line item table in a better way. Description here: https://app.asana.com/0/1204452991162645/1204539728170177/f
    updateObjectFragment: async (id: number | string, data: ColumnDataTypes) =>
      api.patch<Partial<LineItem>>(`line-items/${id}/detailed-update/`, data),
    // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
    delete: async (id: number) => api.delete<any>(`line-items/${id}/`),
    editBudgetItemPeriod: async (
      data: Partial<{
        project_id?: number;
        budget_id?: number | null;
        cost_code_id: number;
        description: string;
        amount: number;
      }>
    ) => api.post('/line-items/budget-item/', data),
    bulkUpdate: async (data: Partial<LineItem>[]) => api.patch<LineItem[]>('line-items/bulk-update-list/', data)
  },
  payment: {
    create: async (data: Partial<Payment>) => api.post('payments/', data),
    update: async (id: number, data: Partial<Payment>) => api.patch(`payments/${id}/`, data),
    delete: async (id: number) => api.delete(`payments/${id}/`),
    getById: async (id: number) => api.get(`payments/${id}/`),
    // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
    list: async (page: number, filters: any) => {
      return api.get('payments/', {
        params: {
          ...filters,
          page: page ? page : 1
        },
        paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
      });
    }
  },
  paymentMethods: {
    getMethods: async (params = {}) =>
      api.get<(PaymentMethodCard | PaymentMethodBank)[]>('payments/methods/', { params }),
    getBalanceToken: async () => api.get<{ token: string }>('payments/methods/token/'),
    getMercoaToken: async () => api.get<string>(`payments/accounts/mercoa-entity-token/`),
    makePaymentV2: async (
      data: Partial<{
        account_id: number;
        payment_method_id: number | null;
        charge_date: Date;
        invoices: Partial<{
          id: number;
          amount_paid: unknown;
          payout_method: string | null;
        }>[];
      }>
    ) => api.post('/v2/payments/transactions/', data),
    deletePayment: async (id: number) => api.delete(`payments/transactions/${id}/`),
    retrieveMethod: async (id: number) => api.get(`payments/methods/${id}/`),
    updateMethod: async (
      id: number,
      data: {
        bank?: {
          institution_name?: string | null;
          account_name?: string | null;
          full_name?: string | null;
          routing_number?: string | null;
          account_number?: string | null;
        };
        alias?: string | null;
        is_default?: boolean | null;
      }
    ) => api.patch(`payments/methods/${id}/`, data),
    deleteMethod: async (id: number) => api.delete(`payments/methods/${id}/`),
    signatureUpload: async (id: number, data: Blob) => {
      const formData = new FormData();
      formData.append('content', data);

      return api.post(`payments/methods/${id}/upload/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    },
    verify: async (id: number, data: number[]) => api.post(`payments/methods/${id}/bank/verify/`, data)
  },
  docusign: {
    getTemplates: async () => api.post<DocusignTemplate[]>('docusign/templates/'),
    getRecipients: async ({ template_id }: { template_id: string }) =>
      api.post<DocusignRecipient[]>('docusign/recipients/', { template_id }),
    sendTemplate: async (data: DocusignSignature) =>
      api.post<{ message: string; success: boolean }>('docusign/documents-signature/', data),
    uploadTemplateDocument: async ({
      company_id,
      template_id,
      file
    }: {
      company_id: number;
      template_id: string;
      file: File;
    }) => {
      const formData = new FormData();
      formData.append('content', file);

      const externalData = {
        docusign: {
          template_id: template_id
        }
      };

      formData.append('external_data', JSON.stringify(externalData));

      return api.post(`companies/${company_id}/upload-template/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }
  },
  plaidConnections: {
    getPlaidLinkToken: async ({ company_id, pm_id, pc_id }: { company_id: number; pm_id?: number; pc_id?: number }) =>
      api.post<{
        link_token: string;
      }>('tokens/get-link-token/', {
        company_id,
        payment_method_id: pm_id,
        plaid_item_id: pc_id
      }),
    getPlaidAccessToken: async (data: {
      public_token: string;
      institution: PlaidInstitution | null;
      company_id: number;
      accounts: PlaidAccount[];
    }) =>
      api.post<{
        message: string;
      }>('tokens/get-access-token-v2/', data),
    markTransactionAsSeen: async (transactionId: number) =>
      api.patch<PlaidTransaction>(`plaid-connection-transactions/${transactionId}/`, { marked_as_seen: true }),
    getLinkedTransactionsOnPm: async (plaid_item_id: number, payment_method_id: number, pageNumber: number) =>
      api.get<PaginatedResponse<PlaidTransaction>>(
        `plaid-connection-transactions/linked-transactions?pc_id=${plaid_item_id}&pm_id=${payment_method_id}&page_size=100&page=${pageNumber}`
      ),

    update: async (
      id: number,
      data: {
        active?: boolean;
        multiple_card_account?: boolean;
      }
    ) => api.patch<PlaidConnection>(`plaid-connections/${id}/`, data),
    list: async () => api.get<PlaidConnection[]>(`plaid-connections/?page_size=100`),
    markPlaidItemAsUpdated: async (id: number) =>
      api.patch<PlaidItem>(`plaid-connections/${id}/`, {
        credentials_need_update: false,
        pending_expiration: false
      }),
    deleteConnection: async (id: number) => api.delete(`plaid-connections/${id}/`),
    getTransactions: async (
      plaid_item_id?: number | null,
      payment_method_id?: number | null,
      pageNumber?: number,
      onlyMissingReceipt: boolean = false,
      page_size: number = 100
    ) => {
      plaid_item_id = plaid_item_id || null;
      payment_method_id = payment_method_id || null;
      pageNumber = pageNumber || 1;
      return api.get<PaginatedResponse<PlaidTransaction>>(
        `plaid-connection-transactions?pc_id=${plaid_item_id}&pm_id=${payment_method_id}&page_size=${page_size}&page=${pageNumber}${
          onlyMissingReceipt ? '&linked_invoice_is_null=True&marked_as_seen=False' : ''
        }`
      );
    },
    getTxns: async (id: number) => api.post<PlaidConnection>(`plaid-connections/${id}/get-transactions/`),
    getInvoicesWithoutTransactions: async (transactionId: number) =>
      api.get<Invoice[]>('v2/invoices/get-valid-invoices-for-linking', {
        params: { transaction_id: transactionId }
      }),
    linkInvoiceToTransaction: async (invoiceId: number, transactionId: number) =>
      api.post<PlaidTransaction>(`plaid-connection-transactions/${transactionId}/link/`, {
        invoice_id: invoiceId
      }),
    unlinkTransaction: async (transactionId: number) =>
      api.post<PlaidTransaction>(`plaid-connection-transactions/${transactionId}/unlink/`, {}),
    updateMethod: async (
      id: number,
      data: {
        plaid_pm_profile_ids?: number[];
        plaid_pm_profile_ids_to_remove?: number[];
        active?: boolean;
        notifications_enabled?: boolean;
        plaid_item_id?: number | null;
        plaid_account_owner?: string | null;
      }
    ) => api.patch<PaymentMethodCard | PaymentMethodBank>(`plaid-cards/${id}/?plaid=true`, data),
    listMethods: async () => api.get<(PaymentMethodCard | PaymentMethodBank)[]>(`plaid-cards/?plaid&page_size=1000`)
  },
  companyCards: {
    getPlaidLinkToken: async ({ company_id, pm_id, pc_id }: { company_id: number; pm_id?: number; pc_id?: number }) =>
      api.post<{
        link_token: string;
      }>('tokens/get-link-token/', {
        company_id,
        payment_method_id: pm_id,
        plaid_item_id: pc_id
      }),

    markTransactionAsSeen: async (transactionId: number) =>
      api.patch<PlaidTransaction>(`plaid-transactions/${transactionId}/`, { marked_as_seen: true }),
    getPlaidAccessToken: async (data: {
      public_token: string;
      institution: PlaidInstitution | null;
      company_id: number;
      accounts: PlaidAccount[];
      payment_method_id: number | null;
    }) =>
      api.post<{
        message: string;
      }>(data.payment_method_id != null ? 'tokens/get-access-token/' : 'tokens/get-access-token-fresh/', data),
    list: async () => api.get<(PaymentMethodCard | PaymentMethodBank)[]>(`plaid-cards/?plaid&page_size=1000`),
    update: async (
      id: number,
      data: {
        plaid_pm_profile_ids?: number[];
        plaid_pm_profile_ids_to_remove?: number[];
        active?: boolean;
        notifications_enabled?: boolean;
        plaid_item_id?: number | null;
      }
    ) => api.patch<PaymentMethodCard | PaymentMethodBank>(`plaid-cards/${id}/?plaid=true`, data),
    getTransactions: async (cardId: number, pageNumber: number, onlyMissingReceipt: boolean = false) =>
      api.get<PaginatedResponse<PlaidTransaction>>(
        `plaid-transactions?pm_id=${cardId}&page_size=100&page=${pageNumber}${
          onlyMissingReceipt ? '&linked_invoice_is_null=True&marked_as_seen=False' : ''
        }`
      ),
    getTxns: async (id: number) =>
      api.post<PaymentMethodCard | PaymentMethodBank>(`plaid-cards/${id}/get-transactions/`),
    getInvoicesWithoutTransactions: async () => api.get<Invoice[]>(`/v2/invoices/get-valid-invoices-for-linking/`),
    getUnlinkedTransactions: async (paymentMethodID: number) =>
      api.get<PaginatedResponse<PlaidTransaction>>(`/plaid-transactions/?payment_method=${paymentMethodID}`),
    linkInvoiceToTransaction: async (invoiceId: number, transactionId: number) =>
      api.post<PlaidTransaction>(`plaid-transactions/${transactionId}/link/`, {
        invoice_id: invoiceId
      }),
    unlinkTransaction: async (transactionId: number) =>
      api.post<PlaidTransaction>(`plaid-transactions/${transactionId}/unlink/`, {}),
    deleteMethod: async (id: number) => api.delete(`payments/methods/${id}/?plaid`),
    markPlaidItemAsUpdated: async (item_id: number) =>
      api.patch<PlaidItem>(`plaid-items/${item_id}/`, {
        credentials_need_update: false,
        pending_expiration: false
      })
  },
  adjustments: {
    create: async (data: { budget_id: number; status: AdjustmentStatus }) =>
      api.post<BudgetAdjustment>('adjustments/', data),
    update: async (id: number, data: Partial<BudgetAdjustment>) =>
      api.patch<BudgetAdjustment>(`adjustments/${id}/`, data),
    delete: async (id: number) => api.delete(`adjustments/${id}/`),
    createItem: async (data: {
      adjustment_id: number;
      amount: number;
      description?: string;
      adjust_type: string;
      budget_item_id: number;
    }) => api.post('adjustment-items/', data),
    updateItem: async (id: number, data: Partial<BudgetAdjustmentItem>) =>
      api.patch<BudgetAdjustmentItem>(`adjustment-items/${id}/`, data),
    deleteItem: async (id: number) => api.delete(`adjustment-items/${id}/`)
  }
};

export default accountingAPI;
