import { FC, ReactNode, useMemo, useContext, createContext, useEffect, useState, useCallback } from 'react';
import posthog, { PostHog } from 'posthog-js';
import * as Sentry from '@sentry/react';
import { AxiosError, AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';

import { isJWTExpired } from '@global-contexts/utils/authUtils';

import userAPI from '@global-apis/user';
import authAPI from '@global-apis/auth';
import companyAPI from '@global-apis/company';
import generalAPI, { GeneralData } from '@global-apis/general';

import { ADMIN, MANAGER } from '@global-utils/defaultValues';

import { ALLOWED_THIRD_APP } from '@root/constants';

import { useCostCodesWithoutParent } from '@global-hooks/costCode';
import { useActiveProjectList } from '@global-hooks/project';
import { useActiveUserList } from '@global-hooks/users';
import { useVendorList } from '@global-hooks/vendor';

import User, { UserGroup } from '@global-interfaces/User';
import Company, { CompanyIntegrations } from '@global-interfaces/Company';
import Address from '@global-interfaces/Address';

declare global {
  interface Window {
    // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
    dataLayer: any[];
  }
}

interface Flags {
  disableApprovalConfirmation: boolean;
  companyCardTransactionRefresh: boolean;
  paymentTransactions: boolean;
  ownerChangeOrder: boolean;
  invoiceTag: boolean;
  agaveConnector: boolean;
  companyCards: boolean;
}
export interface AuthContextType {
  isFlagsReady: boolean;
  flags: Flags;
  // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
  generalSettings: any;

  isSidebarCondensed: boolean;
  setIsSidebarCondensed: (condensed: boolean) => void;

  user: User | null;
  userNavigator: { isMacOs: boolean };
  users: User[];
  groups: UserGroup[];
  loading: boolean;
  started: boolean;
  // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
  error: any;
  // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
  setError: (error: any) => void;

  authTokenRefreshChecking: boolean;

  company: Partial<CompanyAuth>;
  fetchCompanyData: () => Promise<void>;
  switchCompany: (company: number) => Promise<void>;
  token: string | null;
  refreshToken: string | null;
  updateToken: () => void;

  isAuthenticated: () => boolean;
  loginAfterMfa: (access: string, refresh: string) => void;
  logout: () => void;

  createUser: (userDetails: Partial<User>) => Promise<AxiosResponse<User, Error>>;
  updateUser: (id: number, data: Partial<User>) => Promise<void | AxiosResponse<User>>;
  refetchUserInfo: () => void;
  // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
  updatePassword: (id: any, data: any) => void;

  isUsersReady: boolean;
  isLoadingUsers: boolean;
  listUsers: (page?: number, params?: { page_size: number }) => Promise<User[]>;
  // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
  updateProfile: (id: any, data: any) => void;
  // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
  updateCurrentUser: (id: any, data: any) => void;

  integrationAvailable: Partial<CompanyIntegrations>;
}

export interface CompanyAuth extends Omit<Company, 'address'>, Omit<Address, 'id'> {
  address?: string;
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  //this user should be always the application user and/or currentUser
  const [user, setUser] = useState<User | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const userNavigator = useMemo(() => {
    // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
    const platform = (navigator as any)?.userAgentData?.platform || navigator?.platform || 'unknown';

    const isMacOs = platform.toLowerCase().includes('mac');

    return {
      isMacOs
    };
  }, []);
  const emptyFlags = {
    disableApprovalConfirmation: false,
    companyCardTransactionRefresh: false,
    paymentTransactions: false,
    ownerChangeOrder: false,
    invoiceTag: false,
    agaveConnector: false,
    isNewReview: false,
    companyCards: false
  };

  const [groups, setGroups] = useState<UserGroup[]>([]);
  const [company, setCompany] = useState<Partial<CompanyAuth>>({});
  const [generalSettings, setGeneralSettings] = useState<GeneralData>();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoadingUsers, setLoadingUsers] = useState(false);
  const [flags, setFlags] = useState<Flags>(emptyFlags);
  const [isFlagsReady, setIsFlagsReady] = useState(false);
  const [isUsersReady, setIsUsersReady] = useState(false);
  const [isGeneralSettingsReady, setIsGeneralSettingsReady] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [authTokenBeingRefreshed, setAuthTokenBeingRefreshed] = useState(false);
  const [authTokenRefreshChecking, setAuthTokenRefreshChecking] = useState(false);
  const [loadedPostHog, setLoadedPostHog] = useState<null | PostHog>(null);

  const [shouldFetchData, setShouldFetchData] = useState(false);

  useCostCodesWithoutParent({ staleTime: 1000 * 60 * 40, enabled: shouldFetchData });
  useActiveProjectList({ staleTime: 1000 * 60 * 40, enabled: shouldFetchData });
  useVendorList({ staleTime: 1000 * 60 * 40, enabled: shouldFetchData });

  const { refetch: refetchUserList } = useActiveUserList({
    staleTime: 1000 * 60 * 40,
    enabled: shouldFetchData
  });

  // remove this when we have a better solution for the sidebar or when we remove the sidebar
  const [isSidebarCondensed, setIsSidebarCondensed] = useState(true);

  const isAuthenticated = useCallback(() => {
    if (authTokenBeingRefreshed) {
      return true;
    }
    const cacheToken = localStorage.getItem('__token');
    const cacheRefreshToken = localStorage.getItem('__refresh-token');

    if (!cacheToken) {
      setTimeout(() => {
        setToken(null);
        setRefreshToken(null);
      }, 0);
      return false;
    }

    if (!token || !refreshToken) {
      // it is necessary to use setTimeout to avoid the error: "Warning: Cannot update a component (`AuthProvider`) while rendering a different component (`PrivateRoute`). To locate the bad setState() call inside `PrivateRoute`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render"
      setTimeout(() => {
        setToken(cacheToken);
        setRefreshToken(cacheRefreshToken);
      }, 0);
    }

    const { accessTokenIsExpired } = isJWTExpired(cacheToken);

    if (accessTokenIsExpired) {
      return false;
    }

    return true;
  }, [authTokenBeingRefreshed, token, refreshToken]);

  const started = useMemo(() => {
    return Boolean(!loading && user);
  }, [loading, user]);

  const includeAdditionalInfo = (userData: User, groupsData?: UserGroup[]) => {
    const foundGroups = groupsData ?? groups;

    const permissions = userData.groups.map((userGroup) => foundGroups.find((group) => group.id === userGroup)!.name);

    return {
      ...userData,
      full_name: `${userData.first_name} ${userData.last_name}`,
      permissions,
      isAdmin: permissions.includes(ADMIN),
      isManager: permissions.includes(MANAGER)
    } as User;
  };

  const setUserInfo = (res: User, foundGroups: UserGroup[]) => {
    setUser(includeAdditionalInfo(res, foundGroups));
  };

  useEffect(() => {
    if (isGeneralSettingsReady) {
      if (generalSettings?.posthog_enabled) {
        posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY, {
          api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
          // biome-ignore format:  no break line
          ...(ALLOWED_THIRD_APP === false && { autocapture: false, debug: false, disable_session_recording: true, capture_performance: false }),
          session_recording: { maskAllInputs: false, maskInputOptions: { password: true } },
          loaded: (postHogLoaded) => {
            postHogLoaded.onFeatureFlags((postHogFlags) => {
              setFlags(postHogFlags.reduce((a, v) => ({ ...a, [v]: true }), emptyFlags));
              setIsFlagsReady(true);
            });
            setLoadedPostHog(postHogLoaded);
          }
        });
      } else {
        setIsFlagsReady(true);
      }
    }
  }, [generalSettings, isGeneralSettingsReady]);

  useEffect(() => {
    if (user && loadedPostHog) {
      loadedPostHog.identify(user.id.toString(), {
        name: user.full_name,
        email: user.email,
        company_id: user.profile.company?.id.toString(),
        company_name: user.profile.company?.name
      });
    }
  }, [loadedPostHog, user]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe to ignore
  const fetchResources = useCallback(async () => {
    setLoading(true);

    await Promise.all([
      // general settings
      // biome-ignore format: no break line
      generalAPI.fetch().then(({ data }) => {
        setGeneralSettings(data?.[0]);
        setIsGeneralSettingsReady(true);
      }),
      // user info
      // biome-ignore format: no break line
      authAPI.group.list().then(async ({ data: { results: groupResults } }) => {
        setGroups(groupResults);

        return userAPI.me().then(async ({ data: userData }) => {
          setUserInfo(userData, groupResults);

          window.dataLayer?.push?.({
            company_name: userData.profile?.company?.name,
            company_id: userData.profile?.company?.id,
            user_name: userData.email,
            user_id: userData.id,
            event: 'login-btn'
          });

          Sentry.getCurrentScope()?.setTag('company', userData.profile?.company?.name);
          Sentry.getCurrentScope()?.setUser({
            id: userData.id,
            email: userData.email,
            company_id: userData.profile?.company?.id,
            company_name: userData.profile?.company?.name
          });

          const foundCompany = userData.profile?.company;

          if (foundCompany) {
            localStorage.setItem('__company', foundCompany.id.toString());
            localStorage.setItem('__user', userData.id.toString());

            setCompany({
              ...foundCompany,
              zip_code: foundCompany.address?.zip_code ?? '',
              address: foundCompany.address?.address_1 ?? '',
              address_1: foundCompany.address?.address_1 ?? '',
              address_2: foundCompany.address?.address_2 ?? '',
              city: foundCompany.address?.city ?? '',
              state: foundCompany.address?.state ?? '',
              phone: foundCompany.address?.phone ?? '',
              country: foundCompany.address?.country ?? null,
              website: foundCompany.website,
              email: foundCompany.email,
              ein: foundCompany.ein,
              logo: foundCompany.logo,
              partner_logo: foundCompany.partner_logo,
              company_flags: foundCompany.company_flags ?? {},
              integrations_available: Object.assign(
                {
                  balance_available: false,
                  clockshark_available: false,
                  docusign_available: false,
                  dropbox_available: false,
                  gmail_available: false,
                  outlook_available: false,
                  procore_available: false,
                  procore_timecard_available: false,
                  quickbooks_available: false,
                  quickbooks_desktop_available: false,
                  quickbooks_desktop_mac_available: false,
                  quickbooks_desktop_time_available: false,
                  quickbooks_time_available: false,
                  sage_available: false
                },
                foundCompany.integrations_available
              )
            });
          }
        });
      })
    ]);

    setLoading(false);
  }, []);

  const refreshTokenIfNeeded = async () => {
    const accessTokenFromLS = localStorage.getItem('__token');

    if (!accessTokenFromLS) {
      return;
    }
    setAuthTokenBeingRefreshed(true);
    const refreshTokenFromLS = localStorage.getItem('__refresh-token');
    const { accessTokenWillExpireWithinBufferTime } = isJWTExpired(accessTokenFromLS);
    // proactive measure to refresh token 60 seconds before it actually expires
    if (accessTokenWillExpireWithinBufferTime && window.location.pathname !== '/login') {
      try {
        const response = await authAPI.refreshToken(accessTokenFromLS, refreshTokenFromLS);
        localStorage.setItem('__token', response.data.access);
        setToken(response.data.access);
      } catch (error) {
        if ((error as AxiosError)?.response?.status !== 401) {
          Sentry.captureMessage('Something went wrong when refreshing the token', {
            extra: {
              error
            }
          });
        }
        logout();
      }
    }

    setAuthTokenBeingRefreshed(false);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe to ignore
  useEffect(() => {
    setAuthTokenRefreshChecking(true);
    // Refresh token on app initialization. E.g when user refreshes the page or back to the app after a while/long time
    refreshTokenIfNeeded();
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe to ignore
  useEffect(() => {
    // Periodically check and refresh token every 30 seconds
    const interval = setInterval(refreshTokenIfNeeded, 30_000);
    return () => clearInterval(interval);
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe to ignore
  useEffect(() => {
    if (isAuthenticated() && !user && Object.keys(company).length === 0 && !authTokenBeingRefreshed) {
      setShouldFetchData(true);
      fetchResources();
    }
  }, [authTokenBeingRefreshed]);

  const updateToken = async () => {
    const { data: newToken } = await authAPI.refreshToken(token, refreshToken);

    localStorage.setItem('__token', newToken.access);
    setToken(newToken.access);
  };

  const fetchCompanyData = async () => {
    await companyAPI.fetch(company.id!).then((res) => {
      const companyInfo = res.data;
      setCompany({
        ...company!,
        company_flags: companyInfo.company_flags,
        address: companyInfo.address?.address_1 ?? '',
        address_1: companyInfo.address?.address_1 ?? '',
        address_2: companyInfo.address?.address_2 ?? '',
        city: companyInfo.address?.city ?? '',
        state: companyInfo.address?.state ?? '',
        country: companyInfo.address?.country ?? null,
        zip_code: companyInfo.address?.zip_code ?? '',
        phone: companyInfo.address?.phone ?? '',
        website: companyInfo.website,
        email: companyInfo.email,
        ein: companyInfo.ein,
        logo: companyInfo.logo
      });
    });
  };

  const switchCompany = async (companyId: number) => {
    const { data } = await userAPI.switchCompany(companyId);

    const decoded = jwtDecode<{ company_id?: number; user_id?: number }>(data.access);

    localStorage.setItem('__token', data.access);
    localStorage.setItem('__refresh-token', data.refresh);
    localStorage.setItem('__company', decoded?.company_id?.toString() ?? '');
    localStorage.setItem('__user', decoded?.user_id?.toString() ?? '');

    setToken(data.access);
    setRefreshToken(data.refresh);
  };

  const updateUser = async (id: number, data: Partial<User>) => {
    setError(null);

    return userAPI
      .update(id, data)
      .then((res) => {
        refetchUserList(); // force refetch the user list on entire app
        const idx = users.findIndex((item) => item.id === res.data.id);
        const users_ = [...users];
        users_[idx] = res.data;

        const foundUsers = users_.map((item) => ({
          ...item,
          permissions: item.groups.map((userGroup) => groups.find((group) => group.id === userGroup)!.name)
        }));

        setUsers(foundUsers);
        return res;
      })
      .catch((err) => {
        setError(err);
      });
  };

  //should be used to update the currentUser / application user
  // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
  const updateCurrentUser = async (id: any, data: any) => {
    return userAPI
      .update(id, data)
      .then((res) => {
        setUserInfo(res.data, groups);
      })
      .catch((err) => {
        setError(err);
      });
  };

  // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
  const updatePassword = async (id: any, data: any) => {
    return userAPI.setPassword(id, data).catch((err) => setError(err));
  };

  const listUsers = async (page = 1, params = { page_size: 1000 }) => {
    setLoadingUsers(true);
    const { data } = await userAPI.list(page, params);

    const foundUsers = data.results.filter((user) => user.is_active).map((item) => includeAdditionalInfo(item));
    setUsers(foundUsers);
    setIsUsersReady(true);
    setLoadingUsers(false);

    return foundUsers;
  };

  const createUser = async (data: Partial<User>) => {
    return userAPI.createUser(data).then(async (res) => {
      await userAPI.createProfile({
        user: res.data.id,
        company_id: data!.profile!.company!.id
      });

      return res;
    });
  };

  // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
  const updateProfile = async (id: any, data: any) => {
    return userAPI
      .updateProfile(id, data)
      .then((res) => {
        const idx = users.findIndex((item) => item.id === res.data.user);
        const users_ = [...users];
        users_[idx].profile = res.data;

        setUsers(users_);
      })
      .catch((err) => {
        setError(err);
      });
  };

  const loginAfterMfa = (access: string, refresh: string) => {
    localStorage.setItem('__token', access);
    localStorage.setItem('__refresh-token', refresh);

    return fetchResources();
  };

  const refetchUserInfo = async () => {
    return authAPI.group.list().then(async ({ data: { results: groupResults } }) => {
      setGroups(groupResults);

      return userAPI.me().then(async ({ data: userData }) => {
        setUserInfo(userData, groupResults);
      });
    });
  };

  const logout = async () => {
    await authAPI.logout().then(() => {
      localStorage.removeItem('__token');
      localStorage.removeItem('__refresh-token');

      setUser(null);
      setUsers([]);
      setGroups([]);
      setCompany({});
      setError(null);
      setLoading(false);
      setIsUsersReady(false);
    });
  };

  const integrationAvailable = useMemo(() => user?.profile?.company?.integrations_available || {}, [user]);

  return (
    <AuthContext.Provider
      value={{
        isFlagsReady,
        flags: {
          ...flags,
          agaveConnector: company?.company_flags?.agave_connector ?? false
        },
        generalSettings,

        isSidebarCondensed,
        setIsSidebarCondensed,

        user,
        userNavigator,
        users,
        groups,
        loading,
        started,
        error,
        setError,

        authTokenRefreshChecking,

        company,
        fetchCompanyData,
        switchCompany,

        token,
        refreshToken,
        updateToken,

        isAuthenticated,
        loginAfterMfa,
        logout,

        createUser,
        updateUser,
        refetchUserInfo,
        updatePassword,

        isUsersReady,
        isLoadingUsers,
        listUsers,
        updateProfile,
        updateCurrentUser,

        integrationAvailable
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
