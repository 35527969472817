import { useQuery, useMutation, QueryClient, useQueryClient } from '@tanstack/react-query';
import companiesAPI from '@global-apis/companies';
import Vendor from '@global-interfaces/Vendor';
import { graphqlClient, PaginatedResponse } from '@global-apis/config';
import Address from '@global-interfaces/Address';
import { gql, TypedDocumentNode } from '@apollo/client';

type VendorUpdatePayload = Partial<Omit<Vendor, 'address'> & { address?: Partial<Address> }>;

interface VendorData {
  id: number;
  name: string;
  email: string;
  address_id: number | null;
  bank_id: number | null;
}

interface VendorValueData {
  vendor: Vendor;
}

interface VendorsValueData {
  vendors: {
    results: VendorData[];
  };
}

const VENDOR_QUERY: TypedDocumentNode<VendorValueData> = gql`
  query GetVendor($id: Int!) {
    vendor(id: $id) {
      id
      name
      ein
      email
      phone
      cost_type
      qb_account_sub_type
      sage_id
      sage_available
      is_balance_configured
      company_id
      address {
        id
        address_1
        address_2
        city
        state
        zip_code
        country
        phone
      }
      address_id
      bank {
        id
        account_name
        account_number
        account_type
        balance_id
        full_name
        institution_name
        lob_id
        mercoa_id
        raw_data_balance
        raw_data_lob
        raw_data_mercoa
        routing_number
        signature
        verified
      }
      bank_id
      vendor_settings
    }
  }
`;

const VENDORS_QUERY: TypedDocumentNode<VendorsValueData> = gql`
  query GetVendors {
    vendors {
      results {
        id
        name
        email
        address_id
        bank_id
      }
    }
  }
`;

export const useVendorList = (options = {}) =>
  useQuery<VendorData[], Error>({
    queryKey: ['vendors'],
    queryFn: async () => {
      const { data } = await graphqlClient.query({
        query: VENDORS_QUERY,
        fetchPolicy: 'network-only'
      });

      return data.vendors.results;
    },
    ...options
  });

export const updateVendorOnList = (queryClient: QueryClient, id: number, newVendor: Vendor) => {
  queryClient.setQueryData(['vendors'], (oldQueryData: VendorData[]) => {
    const index = oldQueryData.findIndex((item) => item.id === id);

    if (index === -1) {
      return oldQueryData;
    }

    const updatedItem = {
      ...oldQueryData[index],
      name: newVendor.name,
      email: newVendor.email ?? '',
      address_id: newVendor.address_id ?? null,
      bank_id: newVendor.bank_id ?? null
    };

    const updatedResults = [...oldQueryData];

    updatedResults[index] = updatedItem;

    return updatedResults;
  });
};

export const addVendorToList = (queryClient: QueryClient, newVendor: Vendor, sortKey: keyof Vendor = 'name') => {
  queryClient.setQueryData(['vendors'], (oldQueryData: VendorData[] | undefined) => {
    if (!oldQueryData) {
      return [newVendor];
    }

    const updatedList = [...oldQueryData, newVendor];

    // Order by sortKey
    updatedList.sort((a, b) => {
      const aValue = a[sortKey];
      const bValue = b[sortKey];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue);
      }

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return aValue - bValue;
      }

      return 0;
    });

    return updatedList;
  });
};

export const deleteVendorFromList = (queryClient: QueryClient, id: number) => {
  queryClient.setQueryData(['vendors'], (oldQueryData: VendorData[]) => {
    const updatedResults = oldQueryData.filter((item) => item.id !== id);

    return updatedResults;
  });
};

export const useVendorCreate = (options = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: VendorUpdatePayload) => companiesAPI.vendor.create(data),
    onSuccess: ({ data }: { data: Vendor }) => addVendorToList(queryClient, data),
    ...options
  });
};

export const useVendorDelete = (options = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => {
      await companiesAPI.vendor.delete(id);

      return id;
    },
    onSuccess: (id: number) => deleteVendorFromList(queryClient, id),
    ...options
  });
};

export const useVendorUpdate = (id: number, options = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: VendorUpdatePayload) => companiesAPI.vendor.update(id, data),
    onSuccess: ({ data }: { data: Vendor }) => {
      updateVendorOnList(queryClient, id, data);
      return queryClient.setQueryData(['vendor', id], data);
    },
    ...options
  });
};

export const useVendor = (id: number | null, options = {}) =>
  useQuery<Vendor | null, Error>({
    queryKey: ['vendor', id],
    queryFn: async () => {
      if (!id) {
        return null;
      }

      const { data } = await graphqlClient.query({
        query: VENDOR_QUERY,
        fetchPolicy: 'network-only',
        variables: { id }
      });

      return data.vendor;
    },
    ...options
  });

export const fetchVendorLazy = async (queryClient: QueryClient, id: number) => {
  return await queryClient.fetchQuery({
    queryKey: ['vendor', id],
    queryFn: async () => {
      const { data } = await graphqlClient.query({
        query: VENDOR_QUERY,
        fetchPolicy: 'network-only',
        variables: { id }
      });

      updateVendorOnList(queryClient, id, data.vendor);

      return data.vendor;
    }
  });
};

export const getVendor = (queryClient: QueryClient, id: number) => {
  return queryClient.getQueryData<Vendor>(['vendor', id]);
};

export const vendorSearchUpdate = (
  queryClient: QueryClient,
  id: number | string,
  params: unknown,
  newData: Partial<Vendor>
) => {
  queryClient.setQueryData(['vendors', params], (oldQueryData: PaginatedResponse<Vendor>) => {
    const index = oldQueryData?.results?.findIndex((item) => item.id === id);

    if (index === -1 || !oldQueryData?.results) {
      return oldQueryData;
    }

    const updatedItem: Vendor = { ...oldQueryData.results[index], ...newData };

    const updatedResults = [...oldQueryData.results];

    updatedResults[index] = updatedItem;

    return { ...oldQueryData, results: updatedResults };
  });
};

// BANK

export const useVendorBankFetch = (vendorId: number, options = {}) => {
  return useQuery({
    queryKey: ['vendor-bank', vendorId],
    queryFn: () => companiesAPI.vendor.bank.fetch(vendorId).then((res) => res.data),
    ...options
  });
};

export const useVendorBankUpdate = (id: number, options = {}) =>
  useMutation({
    mutationFn: (data) => companiesAPI.vendor.bank.update(id, data),
    ...options
  });

// ADDRESS

export const useVendorAddressFetch = (vendorId: number, options = {}) => {
  return useQuery<Address>({
    queryKey: ['vendor-address', vendorId],
    queryFn: () => companiesAPI.address.fetch(vendorId).then((res) => res.data),
    ...options
  });
};

export const useVendorAddressCreate = (options = {}) =>
  useMutation({
    mutationFn: (data: Partial<Address>) => companiesAPI.address.create(data),
    ...options
  });

export const useVendorAddressUpdate = (queryClient: QueryClient, id: number, options = {}) =>
  useMutation({
    mutationFn: (data: Partial<Address>) => companiesAPI.address.update(id, data),
    onSuccess: ({ data }: { data: Address }) => queryClient.setQueryData(['vendor-address', id], data),
    ...options
  });

export const vendorAddressUpdate = (queryClient: QueryClient, id: number, newData: Partial<Address>) => {
  queryClient.setQueryData(['vendor-address', id], (oldQueryData: Address) => {
    const updatedItem = { ...oldQueryData, ...newData };

    return updatedItem;
  });
};

export const invalidateVendorAddress = async (queryClient: QueryClient, id: number) =>
  queryClient.invalidateQueries({ queryKey: ['vendor-address', id] });

export const invalidateVendors = (queryClient: QueryClient) => queryClient.invalidateQueries({ queryKey: ['vendors'] });
