import { useQuery, useMutation, QueryClient } from '@tanstack/react-query';
import accountingAPI from '@global-apis/accounting';
import { gql, TypedDocumentNode } from '@apollo/client';
import { graphqlClient } from '@global-apis/config';

interface CostCode {
  id: number;
  code: string;
  description: string;
  label: string;
  account: string;
  parent_id?: number;
  qbo_account: string;
  income_account: string;
  external_data?: {
    qbd_code?: string;
  };
  external_reference?: string;
}

interface CostCodeWithoutParentData {
  costCodes: {
    results: CostCode[];
  };
}

interface CostCodeVariables {
  filters: {
    is_leaf_nodes: boolean;
  };
}

const GET_COST_CODES: TypedDocumentNode<CostCodeWithoutParentData, CostCodeVariables> = gql`
  query GetCostCodesWithoutParent($filters: CompaniesCostCodesFilters, $limit: Int, $sort_by: SortBy) {
    costCodes(filters: $filters, limit: $limit, sort_by: $sort_by) {
      results {
        id
        code
        description
        account
        parent_id
        qbo_account
        income_account
        external_data {
          qbd_code
        }
      }
    }
  }
`;

export const useCostCodeCreate = (options = {}) =>
  useMutation({
    mutationFn: (data: Partial<CostCode & { company_id: number }>) => accountingAPI.costCode.create(data),
    ...options
  });

export const useCostCodeUpdate = (id: number, options = {}) =>
  useMutation({ mutationFn: (data: Partial<CostCode>) => accountingAPI.costCode.update(id, data), ...options });

// it will return only cost codes being not parent. Used on cost code searcher/selector
export const useCostCodesWithoutParent = (options = {}) =>
  useQuery<CostCode[], Error>({
    queryKey: ['cost-codes-without-parent'],
    queryFn: async () => {
      try {
        const { data } = await graphqlClient.query({
          query: GET_COST_CODES,
          fetchPolicy: 'network-only',
          variables: { filters: { is_leaf_nodes: true } }
        });

        return data.costCodes.results.map((costCode) => ({
          ...costCode,
          label: `${costCode.code} - ${costCode.description}`,
          external_reference: costCode.external_data?.qbd_code
        }));
      } catch (err) {
        // biome-ignore lint/suspicious/noConsole: <explanation>
        console.log('error when fetching cost codes without parent', err);
        throw err;
      }
    },
    ...options
  });

// it will return all cost codes being parent or not. Used on budget list
export const useCostCodesWithParent = (options = {}) =>
  useQuery<CostCode[], Error>({
    queryKey: ['cost-codes-with-parent'],
    queryFn: async () => {
      try {
        const { data } = await graphqlClient.query({
          query: GET_COST_CODES,
          fetchPolicy: 'network-only',
          variables: { filters: { is_leaf_nodes: false } }
        });

        return data.costCodes.results.map((costCode) => ({
          ...costCode,
          label: `${costCode.code} - ${costCode.description}`,
          external_reference: costCode.external_data?.qbd_code
        }));
      } catch (err) {
        // biome-ignore lint/suspicious/noConsole: <explanation>
        console.log('error when fetching cost codes without parent', err);
        throw err;
      }
    },
    ...options
  });

export const invalidateCostCodesWithoutParent = (queryClient: QueryClient) =>
  queryClient.invalidateQueries({ queryKey: ['cost-codes-without-parent'] });

export const invalidateCostCodesWithParent = (queryClient: QueryClient) =>
  queryClient.invalidateQueries({ queryKey: ['cost-codes-with-parent'] });
