import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { useParentProjectList } from '@global-hooks/project';

import dotsLoading from '@global-assets/images/dots-loading.svg';

import ProjectList from '@global-components/AppMenu/components/MenuProject/ProjectList';
import { MenuItemProps, MenuItemWithChildrenProps } from '@global-components/AppMenu/types';

import './styles.scss';

const MenuProject = ({ item: project, isCondensed }: MenuItemWithChildrenProps) => {
  const location = useLocation();

  const [isActive, setIsActive] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { isLoading: isLoadingProjects } = useParentProjectList({ staleTime: 1000 * 60 * 40 });

  const [keyboardNavIndex, setKeyboardNavIndex] = useState(-1);
  const navigate = useNavigate();

  const isReady = useMemo(() => project.childrenReady ?? false, [project.childrenReady]);

  const scrollIntoViewRef = useRef<HTMLLIElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const scrollToElement = () => {
    scrollIntoViewRef.current?.scrollIntoView({
      behavior: 'instant',
      block: 'nearest',
      start: 'end'
    } as ScrollIntoViewOptions);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    inputRef.current?.focus();
  };

  const handleExpand = () => {
    setSearchTerm('');
    const newValue = !isExpanded;

    localStorage.setItem(`__menu-${project.id}-is-condensed`, newValue.toString());

    setIsExpanded(newValue);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe to ignore
  useEffect(() => {
    setSearchTerm('');
  }, [isCondensed]);

  useEffect(() => {
    const storedValue = localStorage.getItem(`__menu-projects-is-condensed`);

    if (storedValue != null) {
      setIsExpanded(storedValue === 'true');
    }
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe to ignore
  useEffect(() => {
    if (!isLoadingProjects) {
      if (!location.pathname.startsWith(project.path)) {
        setIsActive(false);
      } else {
        setIsActive(true);
        setIsExpanded(true);
      }
    }
  }, [location.pathname, isLoadingProjects]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe to ignore
  useEffect(() => {
    if (keyboardNavIndex >= 0) {
      scrollToElement();
    }
  }, [keyboardNavIndex]);

  useEffect(() => {
    if (isExpanded) {
      inputRef.current?.focus();
    }
  }, [isExpanded]);

  const filteredChildren = useMemo(() => {
    if (searchTerm === '') {
      return project.children || [];
    }

    const newList =
      project.children
        ?.map((child) => {
          const foundChildren = child.children?.filter((c) => c.name.toLowerCase().includes(searchTerm.toLowerCase()));

          if (foundChildren?.length) {
            return {
              ...child,
              children: foundChildren
            };
          }

          if (child.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            return { ...child, children: [] };
          }

          return null;
        })
        .filter((c) => c) ?? [];

    return (newList as MenuItemProps[]) ?? ([] as MenuItemProps[]);
  }, [project.children, searchTerm]);

  const showExpandIcon = Boolean(project.children?.length) && !isCondensed;

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe to ignore
  useEffect(() => {
    if (searchTerm) {
      setKeyboardNavIndex(-1);

      if (showExpandIcon) {
        setIsExpanded(true);
      }
    }
  }, [searchTerm, showExpandIcon, filteredChildren]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setKeyboardNavIndex((prevCount) => (prevCount === filteredChildren.length - 1 ? prevCount : prevCount + 1));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setKeyboardNavIndex((prevCount) => (prevCount === 0 ? 0 : prevCount - 1));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      navigate(filteredChildren[keyboardNavIndex].path);
    }
  };

  return (
    <li
      onMouseOut={() => setKeyboardNavIndex(-1)}
      onMouseOver={() => inputRef.current?.focus()}
      className={`
        app-menu-project
        ${isCondensed ? 'app-menu-project--condensed' : ''}
      `}
      data-testid="app-menu-menu-item-projects">
      <Stack
        className={`
          app-menu-project__title
        `}
        direction="row"
        alignItems="center"
        justifyContent="space-between">
        <Stack
          className={`
            ${isCondensed ? 'has-arrow--condensed' : ''}
          `}
          onClick={handleExpand}>
          <Stack direction="row" spacing={1} alignItems="center">
            {Boolean(project.icon) && (
              <Box
                sx={{ width: 18, display: 'flex' }}
                data-testid="app-menu-item-icon"
                className={`
                  app-menu-project__title__icon
                  ${isActive ? 'app-menu-project__title__icon--active' : ''}
                `}>
                {project.icon}
              </Box>
            )}
            <Typography
              className={`
                app-menu-project__title__name
                ${isActive ? 'app-menu-project__title__name--active' : ''}
                ${isCondensed ? 'app-menu-project__title__name--condensed' : ''}
              `}>
              {project.name}
            </Typography>
          </Stack>
        </Stack>
        {showExpandIcon &&
          (isExpanded ? (
            <KeyboardArrowDownIcon
              onClick={() => handleExpand()}
              className={`${isActive ? 'app-menu-project__title__expand-icon--active' : ''}`}
            />
          ) : (
            <KeyboardArrowRightIcon
              onClick={() => handleExpand()}
              className={`${isActive ? 'app-menu-project__title__expand-icon--active' : ''}`}
            />
          ))}
      </Stack>

      {!isCondensed && isExpanded && (
        <Stack direction="row" spacing={4} alignItems="center" className="app-menu-project__search">
          <div className="app-menu-project__search__input-container">
            <div className="app-menu-project__search__icon">
              <SearchIcon sx={{ height: '16px' }} />
            </div>
            <input
              ref={inputRef}
              type="text"
              value={searchTerm}
              onKeyDown={onKeyDown}
              onChange={handleSearchChange}
              placeholder="Filter projects..."
              className="app-menu-project__search__input"
              data-testid="children-search-input"
            />
            {searchTerm && (
              <button
                type="button"
                onClick={handleClearSearch}
                className="app-menu-project__search__clear-button"
                aria-label="Clear search">
                <CloseIcon sx={{ height: '18px' }} />
              </button>
            )}
          </div>
        </Stack>
      )}

      {project.childrenReady ? (
        (project.children ?? []).length ? (
          <ProjectList
            projects={filteredChildren}
            isParent
            isReady={isReady}
            isExpanded={isExpanded}
            isCondensed={isCondensed}
          />
        ) : null
      ) : (
        <div data-testid="app-menu-menu-item-loading">
          <Stack justifyContent="center">
            <img src={dotsLoading} alt="loading" className="new-version-loading" height="25" />
          </Stack>
        </div>
      )}
    </li>
  );
};

export default MenuProject;
