import { useMemo } from 'react';

let webSocketInstance: WebSocket | null = null;

const useWebSocket = (route: string) => {
  const exportWebSocket = useMemo(() => {
    if (!webSocketInstance) {
      const token = window.localStorage.getItem('__token');
      webSocketInstance = new WebSocket(`${import.meta.env.VITE_WS_SERVER}?token=${token}`);
      // biome-ignore lint/suspicious/noConsole: <explanation>
      console.log(`Creating WebSocket instance: {route: ${route}}...`);
    } else {
      // biome-ignore lint/suspicious/noConsole: <explanation>
      console.log(`Reusing existing WebSocket instance: {route: ${route}}...`);
    }
    return webSocketInstance;
  }, [route]);

  const deleteWebSocket = () => {
    if (webSocketInstance) {
      // biome-ignore lint/suspicious/noConsole: <explanation>
      console.log('Closing WebSocket instance...');
      webSocketInstance.close();
      webSocketInstance = null;
    }
  };

  return { exportWebSocket, deleteWebSocket };
};

export default useWebSocket;
