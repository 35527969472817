import { ReactNode, KeyboardEvent } from 'react';
import {
  Dialog,
  PaperProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useTheme,
  Breakpoint
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import scssColorsVariables from '@global-assets/scss/config/_colors.module.scss';

import './styles.scss';

interface ExtendedPaperProps extends PaperProps {
  'data-testid'?: string;
}

export const ModalHeader = ({
  onClose,
  children,
  isSubmitting = false,
  height = 6,
  padding = [3, 3],
  ...rest
}: {
  onClose?: () => void;
  children: ReactNode;
  isSubmitting?: boolean;
  height?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  padding?: [0 | 1 | 2 | 3, 0 | 1 | 2 | 3];
}) => {
  const theme = useTheme();

  return (
    <DialogTitle
      {...rest}
      className="modal-mui__dialogTitle"
      sx={{
        height: `${theme.spacing(height)} !important`,
        padding: `${theme.spacing(padding[0])} ${theme.spacing(padding[1])} !important`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
      {children}
      {onClose && (
        <Button
          onClick={onClose}
          disabled={isSubmitting}
          style={{ color: scssColorsVariables.gray700, justifyContent: 'flex-end' }}>
          <CloseIcon />
        </Button>
      )}
    </DialogTitle>
  );
};

export const ModalBody = ({ children, smaller = false, ...rest }: { children: ReactNode; smaller?: boolean }) => {
  return (
    <DialogContent
      dividers
      {...rest}
      className={`modal-mui__dialogContent ${smaller ? 'modal-mui__dialogContent--smaller' : ''}`}>
      {children}
    </DialogContent>
  );
};

export const ModalFooter = ({ children, ...rest }: { children: ReactNode }) => {
  return <DialogActions {...rest}>{children}</DialogActions>;
};

export const Modal = ({
  isOpen,
  fullScreen = false,
  size = null,
  onClose,
  children,
  dataTestid = '',
  PaperProps = {},
  onKeyUp,
  ...rest
}: {
  isOpen: boolean;
  fullScreen?: boolean;
  size?: 'mobile' | 'tablet' | 'laptop' | 'desktop' | 'wide' | null;
  onClose: () => void;
  children: ReactNode;
  dataTestid?: string;
  appelement?: string;
  PaperProps?: ExtendedPaperProps;
  onKeyUp?: (event: KeyboardEvent<HTMLDivElement>) => void;
  rest?: unknown;
}) => {
  return isOpen ? (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth={(fullScreen ? false : size) as Breakpoint}
      PaperProps={
        {
          ...PaperProps,
          'data-testid': dataTestid
        } as ExtendedPaperProps
      }
      className={`modal-mui ${fullScreen ? 'modal-mui--is-fullscreen' : ''}`}
      onKeyUp={onKeyUp}
      {...rest}>
      {children}
    </Dialog>
  ) : null;
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  size: PropTypes.oneOf(['tablet', 'laptop', 'desktop', 'wide']),
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  dataTestid: PropTypes.string
};

ModalBody.propTypes = {
  children: PropTypes.node,
  fixed: PropTypes.bool,
  smaller: PropTypes.bool
};

ModalFooter.propTypes = {
  children: PropTypes.node
};

export default Modal;
