import { agaveAPI as agaveService } from '@global-apis/config';

export interface SyncResponse {
  invoice_id?: string;
  message?: string;
  success: boolean;
  status?: {
    failed: number;
    imported: number;
    total: number;
  };
}

const agaveAPI = {
  admin: {
    getAccount: async (inbuildCompanyId: number | undefined) => {
      return agaveService.get<{
        id: string;
        name: string;
        description: string;
        account_token: string;
        inbuild_id: string;
      }>(`api/v1/agave/account/${inbuildCompanyId}`);
    },
    createAccount: async (bodyParams: {
      inbuild_id: number;
      name: string;
      description: string;
    }) => {
      return agaveService.post<{
        id: string;
        name: string;
        description: string;
        account_token: string;
        inbuild_id: string;
      }>(`api/v1/agave/account`, { bodyParams });
    }
  },
  token: {
    create: async (reference_id: string) => {
      return agaveService.post<{ link_token: string }>('/api/v1/agave/link/token/create', { reference_id });
    },
    public: async (public_token: string, account_id: string) => {
      return agaveService.post('/api/v1/agave/link/token/public', {
        public_token,
        account_id
      });
    }
  },
  sync: async (id: number) => agaveService.post<SyncResponse>(`/api/v1/inbuild/sage/invoice/${id}/sync/`),
  paymentSync: async (id: number) => agaveService.post<SyncResponse>(`/api/v1/inbuild/sage/payments/${id}/sync/`),
  import: {
    costCodes: async () => agaveService.post<SyncResponse>(`/api/v1/inbuild/sage/import-cost-codes/`),
    projects: async () => agaveService.post<SyncResponse>(`/api/v1/inbuild/sage/import-projects/`),
    vendors: async () => agaveService.post<SyncResponse>(`/api/v1/inbuild/sage/import-vendors/`),
    paymentMethods: async () => agaveService.post<SyncResponse>(`/api/v1/inbuild/sage/import-payment-methods/`)
  }
};

export default agaveAPI;
