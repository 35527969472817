import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { MenuItemProps } from '@global-components/AppMenu/types';
import ProjectList from '@global-components/AppMenu/components/MenuProject/ProjectList';
import { useParentProjectList } from '@global-hooks/project';

import './styles.scss';

const SubProjectItem = ({
  project,
  isParent,
  isCondensed
}: {
  project: MenuItemProps;
  isCondensed: boolean;
  isParent?: boolean;
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const navigate = useNavigate();

  const { data: projects } = useParentProjectList({ staleTime: 1000 * 60 * 40 });

  const handleExpand = (event) => {
    event.stopPropagation();
    const newValue = !isExpanded;

    setIsExpanded(newValue);
  };

  const onOpenProject = () => {
    navigate(project.path);
  };

  const showExpandIcon = Boolean(project.children?.length) && !isCondensed;

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe to ignore
  useEffect(() => {
    if (location.pathname !== project.path) {
      setIsActive(false);
    }

    const foundProject = (projects ?? []).find((p) => p.id === project.id);

    const foundParent = foundProject
      ? foundProject.children?.find((child) => location.pathname === `/projects/${child.id!.toString()}`)
      : null;

    if (foundParent) {
      setIsActive(true);
      setIsExpanded(true);
    } else if (
      location.pathname === project.path ||
      (project.parent ? location.pathname === project.parent?.path : false)
    ) {
      setIsActive(true);
      setIsExpanded(true);
    }
  }, [location.pathname]);

  return (
    <li data-testid={`app-menu-menu-item-${project.name}`}>
      <Stack
        className={`
          app-menu-project-item
          ${isActive ? 'app-menu-project-item--active' : ''}
        `}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={onOpenProject}>
        <Typography
          className={`
            app-menu-project-item__name
            ${isParent ? 'app-menu-project-item__name__parent' : ''}
            ${isActive ? 'app-menu-project-item__name--active' : ''}
          `}>
          {project.name}
        </Typography>
        {showExpandIcon &&
          (isExpanded ? (
            <KeyboardArrowDownIcon
              onClick={handleExpand}
              className={`${isActive ? 'app-menu-project-item__expand-icon--active' : ''}`}
            />
          ) : (
            <KeyboardArrowRightIcon
              onClick={handleExpand}
              className={`${isActive ? 'app-menu-project-item__expand-icon--active' : ''}`}
            />
          ))}
      </Stack>

      {(project.children ?? []).length > 0 && (
        <ProjectList projects={project.children} isReady isExpanded={isExpanded} isCondensed={isCondensed} />
      )}
    </li>
  );
};

export default SubProjectItem;
