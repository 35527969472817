import { FC, ReactNode, useContext, createContext } from 'react';
import { useAuth } from '@global-contexts/auth';

interface GTMContextType {
  sendDataLayer: (customData?: Record<string, unknown>) => void;
}

export const GTMContext = createContext<GTMContextType>({} as GTMContextType);

const GTMProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { user: currentUserContext, company: companyContext } = useAuth();

  const sendDataLayer = (customData = {}) => {
    return window.dataLayer?.push({
      company_name: companyContext.name,
      company_id: companyContext.id,
      user_name: currentUserContext?.username,
      user_id: currentUserContext?.id,
      user_firstname: currentUserContext?.first_name,
      user_lastname: currentUserContext?.last_name,
      user_email: currentUserContext?.email,
      user_phone: companyContext?.phone,
      ...customData
    });
  };

  return (
    <GTMContext.Provider
      value={{
        sendDataLayer
      }}>
      {children}
    </GTMContext.Provider>
  );
};

export const useGTMContext = () => useContext(GTMContext);

export default GTMProvider;
