import api from './config';

export interface GeneralData {
  mobile_enabled: boolean;
  posthog_enabled: boolean;
  min_ios_version: string;
  min_android_version: string;
}

const generalAPI = {
  fetch: async () => api.get<GeneralData[]>('/general-settings/')
};

export default generalAPI;
